import React, { useEffect } from "react";
import HomepageLayout from "src/layouts/homepage";
import "./dos.css"; // Import CSS file here

export default function DoNotSellInfo() {
    return (
        <HomepageLayout>
                <iframe src="https://app.termly.io/notify/5f2e9cde-5d73-485e-ba67-5b30e8ed883a" title="description"></iframe>
        </HomepageLayout>
    );
}
